import React from 'react';
import './photoGallery.css'; // Import the CSS file for styling

const PhotoGallery = ({ imageUrls, setSelectedImageId, mediaTypes}) => {
  const [_selectedImageId,_setSelectedImageId] = React.useState(0);
  // const [mediaTypes, setMediaTypes] = React.useState([]);


  React.useEffect(
    ()=>{
      // setting selected image ID in the parent component
      console.log("Selected image with id of: " + _selectedImageId)
      setSelectedImageId(_selectedImageId);
    }
  ,[_selectedImageId]);

  // React.useEffect(
  //   ()=>{
  //     async function identifyMediaTypes() {
  //       // setting selected image ID in the parent component
  //       setMediaTypes([]);
  //       imageUrls.map(async (url, index) => {
  //           const mediaType = await getMediaType(url);
  //           console.log("Media Type: ", mediaType);
  //           setMediaTypes([...mediaTypes, mediaType]);
  //         }
  //       )
  //     }

  //     identifyMediaTypes();
    
  //   }
  // ,[]);

  return (
    <div className="photo-gallery">
      {imageUrls.map((url, index) => {

        return <div key={index} className={`gallery-item ${(index == _selectedImageId) && "selected-item"}`} onClick={() => { (index === _selectedImageId) ? _setSelectedImageId(null) : _setSelectedImageId(index) }}>
          <div>
            {
              mediaTypes[index] == "image" 
              ? <img src={url} alt={`Gallery item ${index}`} />
              : <video controls={true}>
                      <source src={url} />
                </video>
            }
          </div>

          {/* { getMediaType(url) == "Image" 
          ? <img src={url} alt={`Gallery item ${index}`} /> 
          : <video controls={true}>
              <source src={url} type="video/*" />
            </video>} */}
        </div>;
      }
      )}
    </div>
  );
};



const getMediaType = async (url) => {
  // Define image and video extensions
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'];
  const videoExtensions = ['mp4', 'webm', 'ogg', 'mov', 'avi', 'mkv'];

  // Extract the file extension from the URL
  const extension = url.split('.').pop().toLowerCase();
  console.log("media url is: ", (typeof url));

  // Check and return the appropriate media type
  if (imageExtensions.includes(extension)) {
    console.log("Found an image");
    return 'Image';
  } else if (videoExtensions.includes(extension)) {
    console.log("Found a video");
    return 'Video';
  } else {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      if (blob.type.startsWith('image/')) {
        return "Image";
      } else if (blob.type.startsWith('video/')) {
        return "Video";
      } else {
        return "unknown"
      }
    } catch (error) {
      console.error('Failed to fetch the blob:', error);
      return "error"
    }

  }
}

const asyncFunc = async () => {

}


export default PhotoGallery;
