import React from 'react';

const SubscriptionCard = () => {
  const cardStyle = {
    maxWidth: '400px',
    margin: '20px auto',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f8f9fa',
    color: '#333',
  };

  const titleStyle = {
    fontSize: '1.5em',
    marginBottom: '10px',
    color: '#0073e6',
    textAlign: 'center',
  };

  const priceStyle = {
    fontSize: '1.25em',
    color: '#ff4500',
    margin: '5px 0',
    textAlign: 'center',
  };

  const listStyle = {
    listStyleType: 'none',
    padding: 0,
    lineHeight: '1.6',
  };

  const itemStyle = {
    margin: '10px 0',
  };

  return (
    <div style={cardStyle}>
      <h2 style={titleStyle}>Subscription Options</h2>
      
      <div style={{ marginBottom: '20px' }}>
        <p style={priceStyle}>$19.99</p>
        <p style={{ textAlign: 'center', fontSize: '0.95em' }}>
          One-Time Profile Creation Fee to create your resume profile.
        </p>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <p style={priceStyle}>$4.99 / Year</p>
        <p style={{ textAlign: 'center', fontSize: '0.95em' }}>
          Annual hosting fee starting one year after profile creation to keep your resume hosted on our site.
        </p>
      </div>

      {/* <div style={{ marginBottom: '20px' }}>
        <p style={priceStyle}>$9.99</p>
        <p style={{ textAlign: 'center', fontSize: '0.95em' }}>
          For each additional re-recording and transcription after the initial save.
        </p>
      </div> */}
    </div>
  );
};

export default SubscriptionCard;
