import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Components
import TestimonialBox from "../Elements/TestimonialBox";

export default function TestimonialSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Talent ID made job hunting effortless! Within two weeks, I landed three interviews. My recruiter was impressed by how professional and clean my digital profile looked."
            author="Emily S., Marketing Specialist"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="I love how easy it was to showcase my skills. The visual portfolio feature set me apart, and now I have my dream role as a UX Designer!"
            author="Michael R., UX Designer"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="I’ve always struggled to create a standout resume, but Talent ID made it simple. Now, I get calls from recruiters weekly."
            author="Priya M., Data Analyst"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="The profile analytics are a game-changer. Knowing which employers viewed my profile helped me target my applications better."
            author="Jason T., Software Engineer"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="I used Talent ID to create a sleek digital resume that I shared with my LinkedIn network. I had three job offers in a month!"
            author="Anna K., Content Writer"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Talent ID helped me land my first job out of college. The interactive skills section really stood out during the hiring process."
            author="Liam P., Junior Developer"
          />
        </LogoWrapper>
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
