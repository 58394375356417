import React, {useEffect} from "react";
import "./ThankYouPage.css";
import { useLocation, useNavigate } from "react-router-dom";


const SignUpThankYouPage = () => {

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const feedbackId = localStorage.getItem("feedbackId");
    if (!location.state || location.state.feedbackId !== feedbackId) {
      navigate("/"); // Redirect to homepage or another fallback route
    } else {
        localStorage.setItem("feedbackId", "");
        // Do stuff here
    }
  }, [location, navigate]);

  const handleNextClick = () => {
    // Replace with your navigation logic, e.g., using React Router
    window.location.href = "/profile";
  };


  return (
    <div className="thank-you-container">
      <div className="thank-you-card">
        <h1 className="thank-you-title">Thank You!</h1>
        <p className="thank-you-message">
          We appreciate you signing up. Your journey with us starts now!
        </p>
        {/* <button className="next-button" onClick={handleNextClick}>
          Continue
        </button> */}
        <button style={{margin: "15px auto"}} onClick={handleNextClick}>
          <div style={{display: "flex"}}>
            <div>
              Continue
            </div>
          </div>
          </button>
      </div>
    </div>
  );
};

export default SignUpThankYouPage;

