import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom"
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../../assets/img/add/1.png";
import AddImage2 from "../../assets/img/add/2.png";
import AddImage3 from "../../assets/img/add/3.png";
import AddImage4 from "../../assets/img/add/4.png";

export default function Features() {

const subtitle1 = `Most traditional resumes fail to capture your true personality and communication skills. TalentID allows you to record video answers to key questions, providing recruiters with a genuine sense of who you are. By demonstrating your confidence, clarity, and professionalism, you’re not just telling them about your skills—you’re showing them.

Why It Matters:

Personal Connection: Employers can see your personality, which is often a deciding factor in hiring.

Stand Out: In a pile of text-based resumes, a video response makes you memorable.

Better Fit: Recruiters can assess how well you align with their company culture even before the interview.
`;
const subtitle2 = `With TalentID, you can upload and organize your projects, achievements, and examples of your work all in one easy-to-access platform. Whether you’re in design, marketing, sales, or any other field, your portfolio speaks volumes about your capabilities.

Why It Matters:

Comprehensive Presentation: Beyond a simple resume, employers can explore the depth of your work.

Tailored Display: Customize your portfolio to highlight the most relevant projects for specific opportunities.

Easy Sharing: Showcase your expertise with just one link, making it convenient for recruiters and hiring managers to review.
`;

const subtitle3 = `Your personalized TalentID profile link makes it simple to share your resume and portfolio across platforms like LinkedIn, Indeed, or even directly via email. It ensures your application doesn’t get lost in the pile and can be viewed anytime, anywhere.

Why It Matters:

Professional Edge: Impress recruiters with a sleek, personalized link that reflects your professionalism.

Increased Visibility: Share your TalentID profile widely to maximize your chances of being noticed.

Convenient Access: Hiring managers can review your profile without downloading attachments or requesting additional information`;

const subtitle4 = `With TalentID, your profile evolves with you. Update your resume, portfolio, or video answers anytime to reflect new achievements, certifications, or roles. Tailor your profile for specific job opportunities to stay ahead in the hiring game.

Why It Matters:

Dynamic Profiles: Keep your resume current without starting from scratch every time.

Customization: Adjust your profile to align with the specific requirements of different roles or industries.

Time-Saving: No need to create multiple resumes—TalentID allows you to update and adapt instantly.
`;



  return (
    <Wrapper id="services">
      {/* <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div> */}
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Why Choose TalentID?</h1>
            <p className="font13">
            TalentID isn’t just another resume tool—it’s a platform designed to give you the edge in a competitive job market. By combining video responses, portfolio showcases, and shareable profiles, it equips you with everything you need to make a strong first impression and secure the interview
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="roller"
                title="Video Responses"
                subtitle={subtitle1}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="monitor"
                title="Showcase Your Portfolio:"
                subtitle={subtitle2}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="browser"
                title=" Shareable Profile Link"
                subtitle={subtitle3}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox icon="printer" title="Easy Profile Updates" subtitle={subtitle4} />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;