import React, {useEffect} from "react";
import "./ThankYouPage.css";
import { useNavigate, useLocation } from "react-router-dom";

const SubsThankYouPage = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const feedbackIdFromServer = params.get('feedbackId');
    const navigate = useNavigate();
  
    useEffect(() => {
        const feedbackId = localStorage.getItem("feedbackId");
        console.log(`Feedback ID from server: ${feedbackIdFromServer}`)
        if (!feedbackIdFromServer || feedbackIdFromServer !== feedbackId) {
          navigate("/"); // Redirect to homepage or another fallback route
        } else {
            localStorage.setItem("feedbackId", "");
            // Do tracking stuff here
        }
      }, [location, navigate]);
  
    const handleNextClick = () => {
      // Replace with your navigation logic, e.g., using React Router
      window.location.href = "/profile";
    };

  return (
    <div className="thank-you-container">
      <div className="thank-you-card">
        <h1 className="thank-you-title">Thank You!</h1>
        <p className="thank-you-message">
          Your transaction has been successfully processed.
        </p>
        {/* <button className="next-button" onClick={handleNextClick}>
          Continue
        </button> */}

        <button style={{margin: "15px auto"}} onClick={handleNextClick}>
          <div style={{display: "flex"}}>
            <div>
              Continue
            </div>
          </div>
          </button>
      </div>
    </div>
  );
};

export default SubsThankYouPage;

