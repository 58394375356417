import React from "react";
import {useNavigate} from "react-router-dom";
import Loader from "../../../loader";
import XButton from "../../../x_button";
import Snackbar from "../../../custom_snackbar";
import styled from "styled-components";
// import Logo from "./logo"
// import XButton from "./x_button";
// import Snackbar from "./custom_snackbar";

function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function Contact() {
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    message: ""
  });


  const [errors, setErrors] = React.useState({
    invalid_credentials: "",
    email_already_in_use: "",
    generic_error: ""
  });


  const [isLoading, setLoading] = React.useState(false);


  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [feedbackType, setFeedbackType] = React.useState('positive');
  const [sbMessage, setSBMessage] = React.useState("");

  const triggerSnackbar = (type, message) => {
    setFeedbackType(type);
    setSBMessage(message);
    setShowSnackbar(true);
  };


  const startLoading = () => {
      setLoading(true);
      console.log(`Starting to load: ${isLoading}`)
  }


  const stopLoading = () => {
    setLoading(false);
    console.log(`stopping to load: ${isLoading}`)
  }


  const handleChange = evt => {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value
    });
  };



  const SendMessage = async (e) => {
    e.preventDefault();
    
    /// Reset errors
    setErrors({
      invalid_credentials: "",
      email_already_in_use: "",
      generic_error: ""
    });

    const email = state.email;
    const message = state.message;
    
    startLoading();
    // await delay(3000);
    try {
      const token = localStorage.getItem('token');
      const email = localStorage.getItem('email')
      const response = await fetch('https://api.yourtalentid.com/api/get-help/send-help-request', {
      // const response = await fetch('http://localhost:5000/api/get-help/send-help-request', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, message}),
      });

      const data = await response.json();

      if (response.ok) {
        // Redirect or update UI as needed
        console.log('Help request sent successfully:', data.token);
        triggerSnackbar("positive", "We've received your request and we'll get back to you shortly. Thank you!")
      } else {
        console.error('send help request failed:', data.message);
        triggerSnackbar("negatiive", "Oops! something went wrong")
        // setErrors({
        //   ...errors,
        //   generic_error : `${data.message}`
        // })

      }
    } catch (error) {
      console.error('Error:', error);
      triggerSnackbar("negatiive", "Oops! something went wrong")
    }
    stopLoading();
  };

  return (
    <Wrapper id="contact">
      {showSnackbar && (
        <Snackbar 
          message={sbMessage} 
          type={feedbackType}
          onClose={() => setShowSnackbar(false)}
        /> 
      )}
        <div style={{margin: "20px 20px"}}>
            <form onSubmit={SendMessage}>
            <h1 className="font40 extraBold">Get in touch</h1>
            {/* <span style={{margin: "15px auto"}}>Start building your professional profile today. Sign up below.</span> */}
            
            <input
            type="text"
            name="first_name"
            value={state.first_name}
            onChange={handleChange}
            placeholder="First Name"
            />
            <input
            type="text"
            name="last_name"
            value={state.last_name}
            onChange={handleChange}
            placeholder="Last Name"
            />
            <input
            type="email"
            name="email"
            value={state.email}
            onChange={handleChange}
            placeholder="Email"
            />

            <input
            type="message"
            name="message"
            value={state.password}
            onChange={handleChange}
            placeholder="Message"
            />

            
            {errors.generic_error != "" ? <div className="form-error"> {errors.generic_error} </div> : <div/>}
            

            <button style={{margin: "15px auto"}} onClick={SendMessage}>
            <div style={{display: "flex"}}>
                <div>
                Send Message
                </div>
                <Loader isLoading={isLoading}/>
            </div>
            </button>

            </form>
        </div>
      

      
    </Wrapper>
  );
}

const Wrapper = styled.section`
  max-width : 700px;
  margin: 0px auto;
`;

export default Contact;
