import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Helmet } from "react-helmet";



const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  rootElement
);
