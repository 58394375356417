import React, { useState } from "react";
import "./styles.css";
import SignInForm from "./SignIn";
import SignUpForm from "./SignUp";
import AuthPage from "./Auth";
import ProfilePage from "./ProfilePage";
import Landing from "./landing_page/screens/Landing";
import SubsThankYouPage from "./thank_you_pages/subs_ty";
import SignUpThankYouPage from "./thank_you_pages/signup_ty";
import UserView from "./UserView";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import ErrorBoundary from './error_boundary';
import SettingsScreen from "./settings";
import SubscriptionScreen from "./subscription/subscription";
// import Contact from "./landing_page/components/Sections/Contact";
import Contact from "./Contact";
// import ForgotPassword from "./forgot-password";
// import ResetPassword from "./reset-password";
import ResetPassword from "./ResetPassword";
import FeedbackPage from "./FeedbackPage";
import useGoogleAnalytics from "./google_analytics";
import useMetaPixel from "./meta_pixel";


export default function App() {
  useGoogleAnalytics("G-WPSMV08700");
  useMetaPixel();
  return (
    <>
      <Routes>
        <Route path="/auth" element={<AuthPage/>}/>
        <Route path="/signUp" element={<SignUpForm/>}/>
        <Route path="/signIn" element={<SignInForm/>}/>
        <Route path="/profile" element={<ProfilePage/>}/>
        <Route path="/reset-password" element={<ResetPassword/>}/>
        <Route path="/feedback" element={<FeedbackPage/>}/>
        <Route path="/settings" element={<SettingsScreen/>}/>
        <Route path="/subscription" element={<SubscriptionScreen/>}/>
        <Route path="/signup-ty-page" element={<SignUpThankYouPage/>}/>
        <Route path="/subs-ty-page" element={<SubsThankYouPage/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/:user" element={<ProfilePage/>}/>
        <Route path="/" element={<Landing/>}/>
        {/* <Route path="/forgotPassword" element={<ForgotPassword/>}/> */}
      </Routes>
    </>

  );
}
